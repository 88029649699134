.header {
  background: #fff;
  padding: 0 5px;
}

.main {
  margin: 0 16px
}

.breadcrumb {
  margin: 16px 0
}

.content {
  margin: 10px 0;
}

.contentWithBackground {
  margin: 10px 0;
  padding: 10px;
  background: #fff;
}

.footer {
  text-align: right;
  font-size: small;
  padding: 5px;
}
